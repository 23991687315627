import axios from "axios"

export const WebRtcApi = {
  webRtc: {
    requestIceServers({ url, token }) {
      return axios({
        url,
        method: "OPTIONS",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    sendLocalOffer({ url, token, data }) {
      return axios({
        url,
        method: "POST",
        headers: {
          "Content-Type": "application/sdp",
          Authorization: `Bearer ${token}`,
        },
        data,
      })
    },
    sendLocalCandidates({ url, token, data, tag }) {
      return axios({
        url,
        method: "PATCH",
        headers: {
          "Content-Type": "application/trickle-ice-sdpfrag",
          "If-Match": tag,
          Authorization: `Bearer ${token}`,
        },
        data,
      })
    },
  },
}

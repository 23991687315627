import axios from "@evercam/shared/api/client/axios"
import type { MobileCaptureSnapshot } from "@evercam/shared/types"

export const IngestApi = {
  drone: {
    createUpload(projectId, params) {
      return axios.post(
        `${axios.env.ingestApiUrl}/drone/${projectId}/upload`,
        null,
        { params }
      )
    },
    reprocessDrone(id, params) {
      return axios.post(
        `${axios.env.ingestApiUrl}/drone/${id}/reprocess`,
        null,
        { params }
      )
    },
    getDrones(params) {
      return axios.get(`${axios.env.ingestApiUrl}/drone/uploads/`, params)
    },
    getArchive(id, params) {
      return axios.get(
        `${axios.env.ingestApiUrl}/drone/uploads/archive/${id}`,
        {
          params,
        }
      )
    },
    deleteUpload(id) {
      return axios.delete(`${axios.env.ingestApiUrl}/drone/uploads/${id}`)
    },
    prepareForUpload(projectId, params) {
      return axios.put(
        `${axios.env.ingestApiUrl}/drone/${projectId}/prepare-upload`,
        null,
        { params }
      )
    },
    upload(projectId, params, payload) {
      return axios.put(
        `${axios.env.ingestApiUrl}/drone/${projectId}/upload`,
        payload,
        { params }
      )
    },
    verifyToken(projectId, params) {
      return axios.get(
        `${axios.env.ingestApiUrl}/drone/uploads/${projectId}/verify`,
        {
          params: { token: params.token },
        }
      )
    },
    calculateMeasuringOperation(projectId, params) {
      return axios.post(
        `${axios.env.ingestApiUrl}/drone/api/${projectId}/calculate-measuring-operation`,
        params
      )
    },
    inspectionTool(projectId, params) {
      return axios.post(
        `${axios.env.ingestApiUrl}/drone/api/${projectId}/inspection-tool`,
        params
      )
    },
    isInspectionEnabled(projectId, params) {
      return axios.post(
        `${axios.env.ingestApiUrl}/drone/api/${projectId}/inspection-enabled`,
        null,
        { params }
      )
    },
    getDroneQueueSize() {
      return axios.get(`${axios.env.ingestApiUrl}/drone/queue`)
    },
    getFlightImages(projectId, params) {
      return axios.get(
        `${axios.env.ingestApiUrl}/drone/api/${projectId}/flight-images`,
        {
          params,
        }
      )
    },
  },
  assets360: {
    get360Assets(params) {
      return axios.get(`${axios.env.ingestApiUrl}/360/assets/`, params)
    },
    deleteUpload(id) {
      return axios.delete(`${axios.env.ingestApiUrl}/360/assets/${id}`)
    },
    getArchive(id, params) {
      return axios.get(`${axios.env.ingestApiUrl}/360/assets/archive/${id}`, {
        params,
      })
    },
    createUpload(projectId, params) {
      return axios.post(
        `${axios.env.ingestApiUrl}/360/${projectId}/upload`,
        null,
        { params }
      )
    },
    upload(id, params, payload) {
      return axios.put(`${axios.env.ingestApiUrl}/360/${id}/upload`, payload, {
        params,
      })
    },
    generate360map(projectId, params) {
      return axios.post(
        `${axios.env.ingestApiUrl}/360/api/${projectId}/generate-360-map`,
        params
      )
    },
    get360QueueSize() {
      return axios.get(`${axios.env.ingestApiUrl}/360/queue`)
    },
  },
  bim: {
    createUpload(projectId, params) {
      return axios.post(
        `${axios.env.ingestApiUrl}/bim/${projectId}/upload`,
        null,
        { params }
      )
    },
    getBIMs(params) {
      return axios.get(`${axios.env.ingestApiUrl}/bim/uploads/`, params)
    },
    getArchive(id) {
      return axios.get(`${axios.env.ingestApiUrl}/bim/uploads/archive/${id}`)
    },
    deleteUpload(id) {
      return axios.delete(`${axios.env.ingestApiUrl}/bim/uploads/${id}`)
    },
    upload(projectId, params, payload) {
      return axios.put(
        `${axios.env.ingestApiUrl}/bim/${projectId}/upload`,
        payload,
        { params }
      )
    },
  },
  mobileCapture: {
    getProjectMobileAssets(params): Promise<MobileCaptureSnapshot[]> {
      return axios.get(`${axios.env.ingestApiUrl}/mobile/assets/`, {
        params,
      })
    },
    getMobileAssetPhoto(assetId, params) {
      return axios.get(`${axios.env.ingestApiUrl}/mobile/assets/${assetId}`, {
        params,
      })
    },
  },
  getTotalQueueSize() {
    return axios.get(`${axios.env.ingestApiUrl}/queue`)
  },
  checkFlightAvailableFeatures(projectId, params) {
    return axios.get(
      `${axios.env.ingestApiUrl}/drone/api/${projectId}/flight-available-features`,
      {
        params,
      }
    )
  },
}

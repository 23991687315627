
/*global google*/
import { debounce } from "@evercam/shared/utils"

export default {
  props: {
    points: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    map: undefined,
    selectedPoint: undefined,
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -15,
      },
    },
  }),
  computed: {
    lines() {
      return this.points.reduce((acc, value) => [...acc, value.location], [])
    },
  },
  watch: {
    points: "centerOnPoints",
  },
  mounted() {
    this.$refs.gmap?.$mapPromise?.then((map) => {
      this.map = map

      this.centerOnPoints()
    })
  },
  methods: {
    centerOnPoints: debounce(function () {
      if (!this.map) {
        return
      }

      const bounds = new google.maps.LatLngBounds()
      for (let m of this.points) {
        if (m.location) {
          bounds.extend(m.location)
        }
      }
      this.map.fitBounds(bounds)
    }),
    icon() {
      return {
        url: "/pathDotColored.png",
        scaledSize: new google.maps.Size(10, 10),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(5, 5),
      }
    },
  },
}

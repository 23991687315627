
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import HourPicker from "@evercam/shared/components/HourPicker"

export default {
  name: "DateTimePicker",
  components: {
    HourPicker,
    EvercamLoadingAnimation,
  },
  props: {
    value: {
      type: [String, Date],
      default: () => new Date().toISOString(),
    },
    isLoadingDays: {
      type: Boolean,
      default: false,
    },
    isLoadingHours: {
      type: Boolean,
      default: false,
    },
    maxDate: {
      type: [Date, String, Number],
      default: () => new Date().toISOString(),
    },
    minDate: {
      type: [Date, String, Number],
      default: () => new Date(0).toISOString(),
    },
    availableDays: {
      type: Array,
      default: () => [],
    },
    availableHours: {
      type: Array,
      default: () => [],
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    disableOldest: {
      type: Boolean,
      default: false,
    },
    disableLatest: {
      type: Boolean,
      default: false,
    },
    dailyEvents: {
      type: [Array, Function],
      default: () => [],
    },
    hourlyEvents: {
      type: [Array],
      default: () => [],
    },
    eventColor: {
      type: String,
      default: "#428dd7AA",
    },
  },
  data() {
    return {
      dateCursor: null,
      isGateEventsLoading: false,
    }
  },
  computed: {
    selectedDay: {
      get() {
        if (!this.value) {
          return
        }

        return this.$moment.tz(this.value, this.timezone).format("YYYY-MM-DD")
      },
      set(date) {
        const newDate = this.$moment.tz(date, this.timezone)
        const newValue = this.$moment
          .tz(this.value, this.timezone)
          .set({
            year: newDate.year(),
            month: newDate.month(),
            date: newDate.date(),
          })
          .format()

        this.$emit("input", newValue)
      },
    },
    selectedHour: {
      get() {
        return this.$moment.tz(this.value, this.timezone).format("H")
      },
      set(hour) {
        if (!hour) {
          return
        }
        const newValue = this.$moment
          .tz(this.value, this.timezone)
          .set({ hour: hour ? `${hour}`.split(":")[0] : 0 })
          .startOf("hour")
          .format()

        this.$emit("input", newValue)
        this.$emit("hour-change", newValue)
      },
    },
    isAdmin() {
      return this.$config.public.appName === "admin"
    },
  },
  watch: {
    selectedDay: {
      handler(date) {
        this.$emit("day-change", date)
      },
      immediate: true,
    },
    async dateCursor(date) {
      const isMonthView =
        this.$moment(date, "YYYY-MM").format("YYYY-MM") === date

      if (!isMonthView) {
        return
      }

      this.$emit("month-change", date)
    },
    availableHours(hours) {
      if (!hours.length) {
        this.selectedHour = ""

        return
      }
      if (!hours.includes(Number.parseInt(this.selectedHour))) {
        this.selectedHour = hours.slice(-1)[0]
      }
    },
    value(selectedTimestamp) {
      this.$emit("change", selectedTimestamp)
      const month = this.$moment
        .tz(selectedTimestamp, this.timezone)
        .format("YYYY-MM")
      if (this.dateCursor !== month) {
        this.dateCursor = month
      }
    },
  },
  created() {
    this.$root.$on(
      "is-gate-events-loading",
      (value) => (this.isGateEventsLoading = value)
    )
  },
  methods: {
    isDateAvailable(date) {
      if (this.isLoadingDays) {
        return true
      }

      return this.availableDays?.includes(
        Number.parseInt(this.$moment.tz(date, this.timezone).format("D"))
      )
    },
    selectFirstFrame() {
      this.$emit("select-oldest")
    },
    selectLastFrame() {
      this.$emit("select-latest")
    },
    getEventColor(date) {
      let day = this.$moment(date).format("DD")

      return this.availableDays.includes(parseInt(day)) ? this.eventColor : ""
    },
  },
}


import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
export default {
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    allowedHours: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      default: "",
    },
    timePicker: {
      type: String,
      default: "",
    },
    loadingHours: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    events: {
      type: [Array],
      default: () => [],
    },
    eventColor: {
      type: String,
      default: "#428dd7AA",
    },
  },
  data() {
    return {
      time: null,
      calendar: [
        [0, 1, 2, 3, 4, 5, 6],
        [7, 8, 9, 10, 11, 12, 13],
        [14, 15, 16, 17, 18, 19, 20],
        [21, 22, 23],
      ],
    }
  },
  computed: {
    buttonActive() {
      if (this.time) {
        return parseInt(this.time?.split(":")[0])
      } else {
        return ""
      }
    },
    isAdmin() {
      return this.$config.public.appName === "admin"
    },
  },
  watch: {
    timePicker() {
      this.time = this.timePicker
    },
  },
  created() {
    this.time = this.timePicker
  },
  methods: {
    changeHour() {
      this.$emit("input", this.time)
      this.$emit("change", this.time)
    },
    formatNumToTime(n) {
      return this.$moment(n, "HH").format("HH:mm")
    },
    getHourItemClass(item) {
      return {
        active2: this.buttonActive == item && this.allowedHours.length,
        inactive: this.buttonActive != item,
        disabled: this.allowedHours.find((v) => v == item) === undefined,
        "hour-item--has-event": this.events.find((e) => e == item),
      }
    },
    onHourSelection(item) {
      this.isLoading = true
      this.time = this.formatNumToTime(item)
      this.changeHour()
    },
  },
}
